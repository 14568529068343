import { Rgba } from './rgba.class';

/**
 * http://www.w3.org/TR/css3-color/
 */
export class ColorsTable {
    public static transparent = new Rgba(0, 0, 0, 0);
    public static aliceblue = new Rgba(240, 248, 255, 1);
    public static antiquewhite = new Rgba(250, 235, 215, 1);
    public static aqua = new Rgba(0, 255, 255, 1);
    public static aquamarine = new Rgba(127, 255, 212, 1);
    public static azure = new Rgba(240, 255, 255, 1);
    public static beige = new Rgba(245, 245, 220, 1);
    public static bisque = new Rgba(255, 228, 196, 1);
    public static black = new Rgba(0, 0, 0, 1);
    public static blanchedalmond = new Rgba(255, 235, 205, 1);
    public static blue = new Rgba(0, 0, 255, 1);
    public static blueviolet = new Rgba(138, 43, 226, 1);
    public static brown = new Rgba(165, 42, 42, 1);
    public static burlywood = new Rgba(222, 184, 135, 1);
    public static cadetblue = new Rgba(95, 158, 160, 1);
    public static chartreuse = new Rgba(127, 255, 0, 1);
    public static chocolate = new Rgba(210, 105, 30, 1);
    public static coral = new Rgba(255, 127, 80, 1);
    public static cornflowerblue = new Rgba(100, 149, 237, 1);
    public static cornsilk = new Rgba(255, 248, 220, 1);
    public static crimson = new Rgba(220, 20, 60, 1);
    public static cyan = new Rgba(0, 255, 255, 1);
    public static darkblue = new Rgba(0, 0, 139, 1);
    public static darkcyan = new Rgba(0, 139, 139, 1);
    public static darkgoldenrod = new Rgba(184, 134, 11, 1);
    public static darkgray = new Rgba(169, 169, 169, 1);
    public static darkgreen = new Rgba(0, 100, 0, 1);
    public static darkgrey = ColorsTable.darkgray;
    public static darkkhaki = new Rgba(189, 183, 107, 1);
    public static darkmagenta = new Rgba(139, 0, 139, 1);
    public static darkolivegreen = new Rgba(85, 107, 47, 1);
    public static darkorange = new Rgba(255, 140, 0, 1);
    public static darkorchid = new Rgba(153, 50, 204, 1);
    public static darkred = new Rgba(139, 0, 0, 1);
    public static darksalmon = new Rgba(233, 150, 122, 1);
    public static darkseagreen = new Rgba(143, 188, 143, 1);
    public static darkslateblue = new Rgba(72, 61, 139, 1);
    public static darkslategray = new Rgba(47, 79, 79, 1);
    public static darkslategrey = ColorsTable.darkslategray;
    public static darkturquoise = new Rgba(0, 206, 209, 1);
    public static darkviolet = new Rgba(148, 0, 211, 1);
    public static deeppink = new Rgba(255, 20, 147, 1);
    public static deepskyblue = new Rgba(0, 191, 255, 1);
    public static dimgray = new Rgba(105, 105, 105, 1);
    public static dimgrey = ColorsTable.dimgray;
    public static dodgerblue = new Rgba(30, 144, 255, 1);
    public static firebrick = new Rgba(178, 34, 34, 1);
    public static floralwhite = new Rgba(255, 250, 240, 1);
    public static forestgreen = new Rgba(34, 139, 34, 1);
    public static fuchsia = new Rgba(255, 0, 255, 1);
    public static gainsboro = new Rgba(220, 220, 220, 1);
    public static ghostwhite = new Rgba(248, 248, 255, 1);
    public static gold = new Rgba(255, 215, 0, 1);
    public static goldenrod = new Rgba(218, 165, 32, 1);
    public static gray = new Rgba(128, 128, 128, 1);
    public static grey = ColorsTable.gray;
    public static green = new Rgba(0, 128, 0, 1);
    public static greenyellow = new Rgba(173, 255, 47, 1);
    public static honeydew = new Rgba(240, 255, 240, 1);
    public static hotpink = new Rgba(255, 105, 180, 1);
    public static indianred = new Rgba(205, 92, 92, 1);
    public static indigo = new Rgba(75, 0, 130, 1);
    public static ivory = new Rgba(255, 255, 240, 1);
    public static khaki = new Rgba(240, 230, 140, 1);
    public static lavender = new Rgba(230, 230, 250, 1);
    public static lavenderblush = new Rgba(255, 240, 245, 1);
    public static lawngreen = new Rgba(124, 252, 0, 1);
    public static lemonchiffon = new Rgba(255, 250, 205, 1);
    public static lightblue = new Rgba(173, 216, 230, 1);
    public static lightcoral = new Rgba(240, 128, 128, 1);
    public static lightcyan = new Rgba(224, 255, 255, 1);
    public static lightgoldenrodyellow = new Rgba(250, 250, 210, 1);
    public static lightgray = new Rgba(211, 211, 211, 1);
    public static lightgreen = new Rgba(144, 238, 144, 1);
    public static lightgrey = ColorsTable.lightgray;
    public static lightpink = new Rgba(255, 182, 193, 1);
    public static lightsalmon = new Rgba(255, 160, 122, 1);
    public static lightseagreen = new Rgba(32, 178, 170, 1);
    public static lightskyblue = new Rgba(135, 206, 250, 1);
    public static lightslategray = new Rgba(119, 136, 153, 1);
    public static lightslategrey = ColorsTable.lightslategray;
    public static lightsteelblue = new Rgba(176, 196, 222, 1);
    public static lightyellow = new Rgba(255, 255, 224, 1);
    public static lime = new Rgba(0, 255, 0, 1);
    public static limegreen = new Rgba(50, 205, 50, 1);
    public static linen = new Rgba(250, 240, 230, 1);
    public static magenta = new Rgba(255, 0, 255, 1);
    public static maroon = new Rgba(128, 0, 0, 1);
    public static mediumaquamarine = new Rgba(102, 205, 170, 1);
    public static mediumblue = new Rgba(0, 0, 205, 1);
    public static mediumorchid = new Rgba(186, 85, 211, 1);
    public static mediumpurple = new Rgba(147, 112, 219, 1);
    public static mediumseagreen = new Rgba(60, 179, 113, 1);
    public static mediumslateblue = new Rgba(123, 104, 238, 1);
    public static mediumspringgreen = new Rgba(0, 250, 154, 1);
    public static mediumturquoise = new Rgba(72, 209, 204, 1);
    public static mediumvioletred = new Rgba(199, 21, 133, 1);
    public static midnightblue = new Rgba(25, 25, 112, 1);
    public static mintcream = new Rgba(245, 255, 250, 1);
    public static mistyrose = new Rgba(255, 228, 225, 1);
    public static moccasin = new Rgba(255, 228, 181, 1);
    public static navajowhite = new Rgba(255, 222, 173, 1);
    public static navy = new Rgba(0, 0, 128, 1);
    public static oldlace = new Rgba(253, 245, 230, 1);
    public static olive = new Rgba(128, 128, 0, 1);
    public static olivedrab = new Rgba(107, 142, 35, 1);
    public static orange = new Rgba(255, 165, 0, 1);
    public static orangered = new Rgba(255, 69, 0, 1);
    public static orchid = new Rgba(218, 112, 214, 1);
    public static palegoldenrod = new Rgba(238, 232, 170, 1);
    public static palegreen = new Rgba(152, 251, 152, 1);
    public static paleturquoise = new Rgba(175, 238, 238, 1);
    public static palevioletred = new Rgba(219, 112, 147, 1);
    public static papayawhip = new Rgba(255, 239, 213, 1);
    public static peachpuff = new Rgba(255, 218, 185, 1);
    public static peru = new Rgba(205, 133, 63, 1);
    public static pink = new Rgba(255, 192, 203, 1);
    public static plum = new Rgba(221, 160, 221, 1);
    public static powderblue = new Rgba(176, 224, 230, 1);
    public static purple = new Rgba(128, 0, 128, 1);
    public static red = new Rgba(255, 0, 0, 1);
    public static rosybrown = new Rgba(188, 143, 143, 1);
    public static royalblue = new Rgba(65, 105, 225, 1);
    public static saddlebrown = new Rgba(139, 69, 19, 1);
    public static salmon = new Rgba(250, 128, 114, 1);
    public static sandybrown = new Rgba(244, 164, 96, 1);
    public static seagreen = new Rgba(46, 139, 87, 1);
    public static seashell = new Rgba(255, 245, 238, 1);
    public static sienna = new Rgba(160, 82, 45, 1);
    public static silver = new Rgba(192, 192, 192, 1);
    public static skyblue = new Rgba(135, 206, 235, 1);
    public static slateblue = new Rgba(106, 90, 205, 1);
    public static slategray = new Rgba(112, 128, 144, 1);
    public static slategrey = ColorsTable.slategray;
    public static snow = new Rgba(255, 250, 250, 1);
    public static springgreen = new Rgba(0, 255, 127, 1);
    public static steelblue = new Rgba(70, 130, 180, 1);
    public static tan = new Rgba(210, 180, 140, 1);
    public static teal = new Rgba(0, 128, 128, 1);
    public static thistle = new Rgba(216, 191, 216, 1);
    public static tomato = new Rgba(255, 99, 71, 1);
    public static turquoise = new Rgba(64, 224, 208, 1);
    public static violet = new Rgba(238, 130, 238, 1);
    public static wheat = new Rgba(245, 222, 179, 1);
    public static white = new Rgba(255, 255, 255, 1);
    public static whitesmoke = new Rgba(245, 245, 245, 1);
    public static yellow = new Rgba(255, 255, 0, 1);
    public static yellowgreen = new Rgba(154, 205, 50, 1);
}
